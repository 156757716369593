/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Predige Rounded Bold by Type Dynamic
 * URL: https://www.myfonts.com/products/bold-predige-rounded-439597
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */


  
@font-face {
  font-family: "PredigeRoundedBold";
  src: url('webFonts/PredigeRoundedBold/font.woff2') format('woff2'), url('webFonts/PredigeRoundedBold/font.woff') format('woff');
}

@font-face {
  font-family: "PlayfairDisplayRegular";
  src: url('webFonts/PlayfairDisplay-Regular.ttf') format('truetype');
}

