/* CMS styling */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,600;0,700;1,500&display=swap');

:root {
    --adminTxt: #444;
    --adminTxtLight: #eee;
    --adminTxtGrey: #888;
    --adminBorderLight: #ffffff40;
    --adminBorderTbl:#ddd;
    --adminBgBlack: #000;
    --adminBgGrey: #eee;
    --adminBgFaded: #f7f7f7;
    --adminBgWhite: #fff;
    --adminCover: rgba(0,0,0,0.25);
    --adminShadow: rgba(0,0,0,0.25);
    --adminDarkerGrey: #ddd;
    --adminWhiteTrans: rgba(255,255,255,0.5);

    --green: rgb(60, 175, 34);
    --ylw: rgb(211, 175, 16);
    --blue: rgb(49, 167, 194);
    --redHeader: #D31C35;
    --red: #D31C35;
}

/* Misc */
.whole-admin h1, 
.whole-admin h2,
.whole-admin h3 {
    text-transform: none;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 18px;
}

.whole-admin h3 {
    font-size: 18px;
}

.whole-admin .float-wrap {
    overflow:auto;
}

.whole-admin .empty {
    text-align: center;
    font-style: italic;
    opacity: 0.75;
    font-size: 15px;
}

.whole-admin .btn,
.whole-admin button,
button.edit-img-btn {
    background: var(--adminBgBlack);
    color: var(--adminTxtLight);
    border: 1px solid var(--adminBgBlack);
    padding: 14px 32px;
    border-radius: 5px;
    font-size: 13px;
    transition: 0.35s;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.whole-admin .btn:hover,
.whole-admin button:hover,
button.edit-img-btn:hover {
    background: var(--adminBgGrey);
    color: var(--adminTxt);
}

.whole-admin .btn:focus,
.whole-admin button:focus {
    box-shadow: 0 0 8px var(--blue);
}

.whole-admin button.add-btn {
    background: none;
    padding: 0;
    text-transform: none;
    color: var(--adminTxt);
    border: none;
    font-weight: 300;
}
.empty { margin: 42px 0; }
.empty p { text-align: center; font-style:italic; opacity: 0.5; }

.whole-admin button.del-btn, .whole-admin button.move-btn {
    background: none;
    padding: 3px 24px 3px 5px;
    margin-left: 22px;
    border: none;
    background: url('../img/cms/delete.svg') right center / 18px auto no-repeat;
    font-size: 12px;
    text-transform: none;
    color: var(--adminTxt);
    font-weight: 300;
    transition: 0.3s;
}
.whole-admin button.del-btn:hover {
    opacity: 0.5;
}
.whole-admin button.move-btn.up {
    background-image: url('../img/cms/up.svg');
}
.whole-admin button.move-btn.down {
    background-image: url('../img/cms/down.svg');
    background-size: 16px auto;
}
.whole-admin button.txt {
    background: none;
    text-transform: none;
    color: var(--adminTxt);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 300;
    border: none;
    padding: 0;
    margin-left: 12px;
}
.whole-admin button.txt:hover {
    text-decoration: none;
}

.greyed-sxn {
    background: var(--adminBgFaded);
    padding: 12px 18px;
    margin-bottom: 22px;
    border-radius: 5px;
}
.greyed-sxn h6 {
    font-size: 16px;
    margin-bottom: 12px;
}

/* Header / footer */
header.admin-header {
    background: var(--adminBgBlack);
    color: var(--adminTxtLight);
    padding: 18px 32px 15px 32px;;
}
header.admin-header .logo-sxn img {
    max-width: 140px;
}
header.admin-header h6 {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 20px;
}
header.admin-header h6 span {
    font-size: 11px;
    font-style: italic;
    font-weight: 100;
    display: block;
    margin: 0;
    opacity: 0.5;
}
header.admin-header nav ul {
    list-style: none;
}
header.admin-header nav ul li {
    display: inline-block;
    margin-left: 20px;
}
header.admin-header nav ul li.new-sxn {
    border-left: 1px solid var(--adminBorderLight);
    padding-left: 20px;
}
header.admin-header nav ul li a {
    text-decoration: none;
    color: var(--adminTxtLight);
    padding: 4px 12px;
    font-size: 13px;
    display: block;
    transition: 0.35s;
}
header.admin-header nav ul li a:hover {
    opacity: 0.5;
}

footer.admin-footer {
    background: none;
    color: var(--adminTxt);
    padding: 18px;
    font-size: 13px;
    text-align: center;
    opacity: 0.5;
}
footer.admin-footer p{
    margin-bottom: 0;
}

/* Core admin styling */
.whole-admin {
    font-family: "Raleway", sans-serif;
    color: var(--adminTxt);
}
.whole-admin h1,
.whole-admin h2,
.whole-admin h3,
.whole-admin h4 {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    margin-bottom: 18px;
}
.whole-admin .main-content {
    margin-top: 32px;
}
.whole-admin .sidebar {
    border-radius: 5px;
    box-sizing: border-box;
}
.whole-admin .sidebar .filter, .whole-admin .sidebar.comments {
    background: var(--adminBgGrey);
    border-radius: 5px;
    padding: 22px 32px;
    margin-bottom: 22px;
    box-sizing: border-box;
}
.whole-admin .sidebar.comments h4 {
    font-size: 22px;
}
.whole-admin .sidebar.comments .ck {
    background: var(--adminBgWhite);
    padding: 0 22px;
}
.whole-admin .sidebar h4 {
    margin-bottom: 12px;
    font-size: 18px;
}
.whole-admin .sidebar nav ul {
    list-style: none;
}
.whole-admin .sidebar nav ul li a {
    text-decoration: none;
    color: var(--adminTxt);
    display: block;
    margin: 0 0 15px 22px;
}
.whole-admin .sidebar nav>ul>li>a {
    font-weight: 600;
    margin-left: 0;
}
.whole-admin .table-wrap {
    overflow: auto;
    height: calc(100vh - 232px);
}
.whole-admin table {
    width: 100%;
    border-collapse: collapse;
    margin: 32px 0;
}
.whole-admin table tr.faded td {
    color: #bbb;
    text-decoration: line-through;
}
.whole-admin table thead tr th {
    border-bottom: 2px solid var(--adminBorderTbl);
    text-align: left;
}
.whole-admin table td, .whole-admin table thead tr th {
    padding: 12px 14px;
}
.whole-admin table tr:nth-child(even) td {
    background: var(--adminBgFaded);
}
.whole-admin table td a, .whole-admin table td button.action-btn {
    color: var(--adminTxt);
    text-decoration: none;
    border: none;
    border-bottom: 1px solid var(--adminTxt);
    display: inline-block;
    padding: 0;
    padding-bottom: 1px;
    line-height: 1em;
    transition: 0.35s;
    margin: 0 8px;
    border-radius: 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 300;
    background: none;
}
.whole-admin table td a:hover, .whole-admin table td button.action-btn:hover {
    border-bottom-color: transparent;
}
.whole-admin table td input {
    border: 1px solid var(--adminBorderTbl);
    padding: 8px 12px;
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    color: var(--adminTxt);
    width: 100%;
    box-sizing: border-box;
}
.whole-admin table td button.sml {
    font-size: 12px;
    padding: 8px 15px;
}
.whole-admin table td button.mrgn-lft {
    margin-left: 8px;
}
.whole-admin table td button.minor {
    background: var(--adminDarkerGrey);
    border-color: var(--adminDarkerGrey);
    color: var(--adminTxt);
}

.whole-admin table td span.status {
    font-size: 12px;
    color: var(--whiteTxt);
    background: var(--red);
    padding: 4px 12px;
    border-radius: 12px;
    text-align: center;
}
.whole-admin table tr td span.status.in-progress { background: var(--ylw); }
.whole-admin table tr td span.status.submitted { background: var(--green); }

.whole-admin .comments .comment {
    background: var(--adminWhiteTrans);
    padding: 8px 12px;
    font-size: 15px;
    line-height: 1.2em;
    border-radius: 2px;
}
.whole-admin .comments .comment + .comment {
    margin-top: 12px;
}
.whole-admin .comments .comment p {
    margin: 0;
}
.whole-admin .comments .comment p+p {
    margin-top: 10px;
}

.whole-admin table tr td.status span.pending_submission,
.whole-admin table tr td.status span.created, 
.whole-admin table tr td.status span.payment_created { background: var(--ylw); }

.whole-admin .react-datepicker-wrapper input[type=text] {
    width: 100%;
    border: 1px solid var(--adminBorderTbl);
    padding: 12px 22px;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    margin-bottom: 18px;
}
.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0;
}
.whole-admin .react-datepicker-wrapper button, button.react-datepicker__navigation--previous, button.react-datepicker__navigation--next {
    background: none;
    border: none;
    padding: 0;
}

/* FORMS */
.whole-admin .input label {
    color: var(--adminTxtGrey);
}
.whole-admin .input.checkbox label {
    margin-left: 38px;
}
.whole-admin .input input, .whole-admin .input select {
    border: 1px solid var(--adminBorderTbl);
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: var(--adminTxt);
    padding: 12px 22px;
    display: block;
    width: 97.5%;
    box-sizing: border-box;
    border-radius: 2px;
}
.whole-admin .input input:focus, .whole-admin .input select:focus { border-color: var(--adminTxt); outline: none; }
hr.password-divider {
    margin-bottom: 18px;
    background-color: var(--adminBorderTbl);
    border: none;
    height: 1px;
}

.whole-admin .permissions {
    background: var(--adminBgFaded);
    padding: 22px 32px;
    border-radius: 12px;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 32px;
}
.whole-admin .permissions h6 {
    font-size: 16px;
    margin-bottom: 18px;
}
.whole-admin .input.checkbox:last-child {
    margin-bottom: 0;
}
.whole-admin .input.checkbox input[type=checkbox] {
    float: left;
    display: block;
    width: 20px;
    margin-right: 18px;
    margin-top: 2px;
}

#pwForm {
    position: relative;
}

.strength-meter {
    /* display: none; */
    position: absolute;
    top: -280px;
    left: 120px;
    width: 420px;
    background: #fff;
    padding: 18px 25px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    font-size: 14px;
    z-index: 1;
    display: none;
}

.strength-meter.display {
    display: block;
}

.strength-meter p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0
}

.strength-meter ul {
    list-style: none;
}

.strength-meter ul li {
    padding-left: 28px;
    line-height: 16px;
    margin: 14px 0;
    background: url('./../img/cross.svg') center left / 16px auto no-repeat;
}

.strength-meter ul li.ticked {
    background: url('./../img/tick.svg') center left / 16px auto no-repeat;
}

/* Drag n drop menu reordering */
.whole-admin .menu-sorter {
    width: 30%;
    float: left;
    margin-right: 10%;
}
.whole-admin .dropzone.menu {
    border: 2px dashed var(--adminBorderTbl);
    border-radius: 12px;
    padding: 22px 32px;
    margin-bottom: 32px;
    min-width: 40%;
}
.whole-admin .dropzone.menu .menu-item-sorter {
    padding: 8px;
}
.whole-admin .dropzone.menu .menu-item-sorter.indent {
    padding-left: 42px;
}

/* Lightbox */
.cover {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--adminCover);
    width: 100%;
    height: 100%;
    z-index: 99999999999;
}
.whole-admin .cover .hover-box, .cover .lb-content {
    width: 600px;
    padding: 22px 32px;
    background: var(--adminBgWhite);
    border-radius: 12px;
    position: fixed;
    top: 20vh;
    left: 50%;
    margin-left: -300px;
    max-height: 60vh;
}
.whole-admin .cover .hover-box.large {
    width: 850px;
    margin-left: -425px;
    overflow-y: auto;
}
.whole-admin .cover .hover-box a.close-lnk, .cover .lb-content a.lb-close, .cover .image-manager a.close-lnk {
    font-size: 0;
    float: right;
    width: 24px;
    height: 24px;
    background: url('./../img/lw/cross-dark.svg') center center / 24px auto no-repeat;
    margin-top: -8px;
    margin-right: -8px;
}
.whole-admin .cover .hover-box h2, .cover .lb-content h6 {
    margin-bottom: 16px;
    font-size: 22px;
}
p.lnk-wrap {
    overflow-wrap: break-word;
    text-align: center;
    padding: 12px;
    background: var(--adminBgFaded);
}

.hover-box .half {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 0 32px 0 0;
}
.hover-box h4 {
    font-size: 16px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--adminBorderTbl);
}
.hover-box ul.detail {
    list-style: none;
    margin-bottom: 32px;
}
.hover-box ul.detail li span {
    font-weight: 600;
    display: inline-block;
    min-width: 175px;
}
.hover-box .half ul.detail li span {
    min-width: 125px;
}

/* Flash message */
.flash-msg {
    background: var(--red);
    color: var(--whiteTxt);
    padding: 12px 22px;
    margin-bottom: 32px;
    border-radius: 8px;
}
.flash-msg.grn {
    background: var(--green);
}


/* CMS block bar */
.cms-block-bar {
    position: fixed;
    right: 0;
    top: 50px;
    z-index: 1000000;
}

.cms-block-bar ul {
    list-style: none;
}

.cms-block-bar ul li {
    margin-bottom: 2px;
    position: relative;
}

.cms-block-bar ul li button {
    font-size: 0;
    background-color: #000;
    background-size: 32px 32px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 0;
    padding: 0;
    width: 56px;
    height: 56px;
    border: none;
}

.cms-block-bar ul li button.success {
    background-color: #34B43F;
}

.cms-block-bar ul li button.error {
    background-color: #B43442;
}

.cms-block-bar ul li button:hover {
    background-color: #777;
}

.cms-block-bar ul li button.publish {
    background-image: url('../img/cms/tick.svg');
}
.cms-block-bar ul li button.dashboard {
    background-image: url('../img/cms/dashboard.svg');
}
.cms-block-bar ul li button.edit {
    background-image: url('../img/cms/edit.svg');
}
.cms-block-bar ul li button.create {
    background-image: url('../img/cms/plus.svg');
}

.cms-block-bar ul li button.delete {
    background-image: url('../img/cms/minus.svg');
}

.cms-block-bar ul li button.reorder {
    background-image: url('../img/cms/move.svg');
    background-size: 26px 26px;
}

.cms-block-bar ul li ul {
    position: absolute;
    top: 0;
    right: 58px;
    display: none;
}

.cms-block-bar ul li ul.visible {
    display: block;
}

.cms-block-bar ul li ul li button {
    font-size: 14px;
    color: #000;
    background-image: none;
    background-color: #ddd;
    z-index: 1;
    width: 250px;
    height: 44px;
}

.cms-block-bar ul li ul li button.mover {
    cursor: move;
}

.cms-block-bar ul li ul li button:hover {
    background-color: #bbb;
}

/* Heading inputs */
h1 input,
h2 input,
h3 input,
h4 input,
p input {
    font-family: "PlayfairDisplayRegular", sans-serif;
    /* text-transform: uppercase; */
    letter-spacing: 0.5px;
    font-size: 44px;
    line-height: 1.1em;
    border: none;
    color: var(--blackTxt);
    background: none;
    width: 100%;
}
h1 input.white,
h2 input.white,
h3 input.white,
h4 input.white,
p input.white {
    color: var(--whiteTxt);
}
h3 input {
    font-size: 44px;
    font-weight: 600;
}
.response h3 input {
    text-align: center;
    font-size: 36px;
}
.response p {
    text-align: center;
}
h4 input {
    font-size: 30px;
    font-weight: 600;
}
p input {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    text-transform: none;
}
p input.centre {
    text-align: center;
}
.social-bar input {
    background: none;
}

.response label { color: var(--adminTxtLight) }
.select select {
    padding: 12px 25px;
    border-radius: none;
    font-family: "Raleway", sans-serif;
    border: 1px solid var(--adminBorderTbl);
}

/* Button editors */
.edit-btn {
    position: relative;
}
.edit-btn .btn-editor {
    background: var(--adminBgWhite);
    position: absolute;
    top: -142px;
    left: 40%;
    width: 40%;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 1px 1px 5px var(--adminShadow);
}
.edit-btn .btn-editor label {
    color: var(--adminTxt);
    text-align: left;
    margin-bottom: 4px;
}
.edit-btn .btn-editor .input {
    margin-bottom: 12px;
}
.edit-btn .btn-editor input {
    width: 100%;
    margin-bottom: 0;
    font-size: 14px;
    padding: 8px 15px;
}

.scroll-table {
    overflow-y: auto;
    white-space: nowrap;
    max-height: 80vh;
}

/* Image editors */
.edit-img {
    position: relative;
    background: rgba(255,255,255,0.1);
    background-size: cover;
    background-position: center center;
}
.response .edit-img {
    height: 320px;
    margin-bottom: 42px;
}
.right-img .edit-img {
    width: 50%;
    height: 95%;
    margin: 2.5% 0%;
    top: 0;
    right: 0;
    background-size: cover;
    background-position: center center;
    position: absolute;
}
.left-img .half-wrap, .right-img .half-wrap {
    width: 50%;
    height: 95%;
    margin: 2.5% 0%;
    top: 0;
    left: 0;
    position: absolute;
    background: #eee;
}
.left-img .half-wrap {
    height: 70%;
    margin-top: 12%;
}
.left-img.short .half-wrap {
    height: 95%;
    margin-top: 2.5%;
}
.right-img .half-wrap {
    left: auto;
    right: 0;
}
.left-img .half-wrap .input, .right-img .half-wrap .input {
    position: absolute;
    width: 300px;
    margin-top: 225px;
    margin-left: -150px;
    left: 50%;
    background: rgba(255,255,255,0.5);
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 2;
}
.left-img .half-wrap .select, .right-img .half-wrap .select {
    margin-top: 120px;
}
.half-wrap .select label {
    font-weight: 600;
}
.half-wrap .select select {
    width: 100%;
}
.left-img .edit-img, .right-img .edit-img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    position: absolute;
    z-index: 1;
}
.response .edit-img button {
    margin-top: 130px;
}
.right-img .edit-img button, .left-img .edit-img button {
    margin: 340px auto 0 auto;
    display: block;
}

/* Image manager */
.img-input, .file-input {
    position: relative;
    margin-bottom: 22px;
    height: 90px;
    width: 97.5%;
    display: block;
    border: 1px dashed var(--adminBorderTbl);
    padding: 32px 32px 65px 32px;
    box-sizing: border-box;
    font-size: 14px;
}
.img-input:hover {
    border-color: var(--adminTxt);
}
.img-input.populated {
    padding-left: 120px;
    padding-top: 28px;
}
.img-input.populated span {
    display: block;
    line-height: 1.2em;
}
.img-input.populated span span {
    opacity: 0.5;
}
.img-input a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    text-decoration: none;
}
.file-input input {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position:absolute;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}
.img-input img {
    height: 70px;
    width: auto;
    top: 10px;
    left: 10px;
    position: absolute;
}
.image-manager {
    width: 80%;
    height: 70vh;
    margin: 15vh 10%;
    background: var(--adminBgWhite);
    border-radius: 12px;
    padding: 22px 25px;
    box-sizing: border-box;
    position: relative;
    color: var(--adminTxt);
    text-align: left;
}
.image-manager h3, .half-responses .response .image-manager h3 {
    text-transform: none;
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    margin: 10px 0 22px 0;
}
.image-manager a.close-lnk {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 1;
}
.image-manager .folders {
    width: 220px;
    height: 100%;
    float: left;
    padding: 14px 22px;
    background: var(--adminBgGrey);
    box-sizing: border-box;
    margin-right: 32px;
    position: relative;
}
.image-manager h3 {
    border-bottom: 1px solid var(--adminBorderTbl);
    padding-bottom: 8px;
    font-size: 24px;
    margin-top: 10px;
}
.image-manager .folders h5 {
    font-size: 20px;
    border-bottom: 1px solid var(--adminBorderTbl);
    padding-bottom: 3px;
    cursor: pointer;
}
.image-manager .folders ul {
    list-style: none;
    margin: 12px 0;
}
.image-manager .folders ul li {
    margin-bottom: 5px;
    font-weight: 300;
    margin-left: 12px;
    font-size: 14px;
}
.image-manager .folders ul li a {
    color: var(--adminTxt);
    text-decoration: none;
}
.image-manager .folders > ul > li {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    margin-left: 0;
    font-style: normal;
}
.image-manager .folders>ul>li>ul {
    display: none;
}
.image-manager .folders>ul>li>ul.open {
    display: block;
}
.image-manager .folders .create-folder {
    position: absolute;
    bottom: 14px;
    left: 14px;
    font-size: 0;
    width: 192px;
    background: var(--adminBgFaded);
    padding: 8px;
    box-sizing: border-box;
}
.image-manager .folders .create-folder label { margin-bottom: 5px; color: var(--adminTxt); }
.image-manager label { color: var(--adminTxt); }
.image-manager .folders .create-folder .input {
    margin-bottom: 0;
}
.image-manager .folders .create-folder input { 
    padding: 5px 12px;
    font-size: 14px;
    margin-bottom: 8px;
}
.image-manager .folders .create-folder button {
    padding: 5px 12px;
    font-size: 12px;
    margin: 0;
}
.image-manager .images, .image-manager .files {
    position: relative;
    margin-left: 252px;
}
.image-manager .img-list, .image-manager .file-list {
    margin: 22px 0;
    overflow: auto;
    max-height: 424px;
}
.image-manager .img-list .img, .image-manager .file-list .file {
    display: block;
    float: left;
    width: 120px;
    height: 140px;
    border: 1px solid var(--adminBgGrey);
    margin: 0 10px 10px 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    position: relative;
}
.image-manager .file-list .file {
    width: 45%;
    margin-right: 5%;
    height: 62px;
}
.image-manager .img-list .img.faded {
    opacity: 0.35;
}
.image-manager .img-list .img a.img-select, .image-manager .file-list .file a.file-select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 0;
}
.image-manager .img-list .img .img-thumb {
    width: 100px;
    height: 90px;
    display: block;
    background: var(--adminBgGrey);
    background-position: center center;
    background-size: cover;
    margin-bottom: 5px;
}
.image-manager .file-list .file .file-thumb {
    width: 42px;
    height: 42px;
    margin-right: 18px;
    float: left;
    background: url('../img/cms/file.svg') center center / auto 36px no-repeat;
}
.image-manager .img-list .img .img-name {
    width: 75px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.image-manager .file-list .file .file-name {
    float: left;
    margin-top: 6px;
}
.image-manager .img-list .img .img-delete, .image-manager .file-list .file .file-delete {
    position: absolute;
    bottom: 12px;
    right: 10px;
    font-size: 0;
    width: 16px;
    height: 16px;
    background: url('../img/cms/delete.svg') center center / 16px auto no-repeat;
    display: block;
    z-index: 2;
}
.image-manager .file-list .file .file-delete {
    bottom: 50%;
    margin-bottom: -8px;
    right: 22px;
}
.image-manager .img-upload {
    float: left;
    display: block;
    height: 100px;
    border: 3px dashed var(--adminBorderTbl);
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
}
.image-manager .img-upload:hover{
    border-color: var(--blue);
}
.image-manager .img-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

/* Slideshow setup */
.whole-admin .slide {
    margin-bottom: 22px;
    background: var(--adminBgFaded);
    padding: 15px 22px;
    clear: none;
    overflow: auto;
    box-sizing: border-box;
}
.whole-admin .slide h5 {
    font-size: 18px;
    margin-bottom: 8px;
}
.whole-admin .slide .lft-part {
    float: left;
    width: 30%;
    margin-right: 2.5%;
}
.whole-admin .slide .rght-part {
    width: 67.5%;
    display: inline-block;
}
.whole-admin .slide .rght-part .input {
    margin-bottom: 15px;
}
.whole-admin .slide .rght-part input {
    padding: 8px 15px;
    font-size: 14px;
}